.riat-custom-icon {
    display: inline-block;
    vertical-align: middle;
    &.arrow-down {
        width: auto;
        height: 1em;
        transform-origin: 50% 40%;
    }
    &.arrow-up {
        width: auto;
        height: 1em;
        transform-origin: 50% 60%;
    }
    &.arrow-left {
        width: 1em;
        height: auto;
        transform-origin: 60% 50%;
    }
    &.arrow-right {
        width: 1em;
        height: auto;
        transform-origin: 40% 50%;
    }
}
