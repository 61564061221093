.testimonial {
    padding: 0;
    margin: 0;
    &--star-rating {
        color: var(--theme--btn--secondary--bg-color);
        font-size: 1rem;
        svg {
            margin-right: 0.1em;
        }
    }
    &--quote {
        position: relative;
        color: var(--theme--color--emphasis);
        font-size: 1.4rem;
        &--start, &--end {
            display: inline-block;
            position: relative;
            width: 1em;
            height: 1em;
            &:before {
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 4.5rem;
                line-height: 1em;
                font-family: $headings-font-family;
                font-weight: 600;
                color: var(--theme--accent--color);
                pointer-events: none;
            }
        }
        &--start {
            margin-left: -1em;
            transform: translate3d(-50%, -20%, 0);
            &:before {
                content: '“';
            }
        }
        &--end {
            transform: translateY(-10%);
            &:before {
                content: '”';
            }
        }
    }
    footer cite {
        font-style: normal;
        strong {
            font-weight: $headings-font-weight;
            color: var(--theme--btn--secondary--bg-color);
        }
        svg {
            font-size: 1.2rem;
            margin-right: 0.5em;
        }
    }
}
