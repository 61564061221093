table.table {
    --table-font-size: max(.875rem, 15px);
    line-height: 1.2;
    tr {
        & > *:first-child {
            padding-left: 0;
        }
        & > *:last-child {
            padding-right: 0;
        }
    }
    @include media-breakpoint-up(sm) {
        --table-font-size: var(--bs-body-font-size);
    }
    thead th {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size: var(--table-font-size);
        text-transform: uppercase;
        color: var(--theme--color--subtle);
    }
    tbody {
        th {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: var(--table-font-size);
            text-transform: uppercase;
            button[data-bs-toggle="modal"] svg {
                vertical-align: -.1em;
            }
            small {
                font-family: $font-family-base;
                font-weight: $font-weight-base;
                text-transform: none;
            }
        }
    }
}
