#main-footer {
                
    .main-footer--affiliations {
        ul {
            margin: 0;
            display: flex;
            width: 100%;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 15px;
            justify-content: center;
            align-items: center;
            li {
                margin: 0;
                img {
                    max-width: 150px;
                    max-height: 100px;
                    object-fit: contain;
                }
            }
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                justify-content: space-between;
                gap: 15px;
                li {
                    max-width: calc((100% - 30px) / 3); // 3 columns
                }
            }
        }
    }

    .main-footer--primary-links {
        ul {
            margin: 0;
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
            li {
                margin: 0;
            }
            @include media-breakpoint-up(lg) {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 15px;
                li {
                    width: calc((100% - 30px) / 3); // 3 columns
                }
            }
        }
    }

    .main-footer--information {
        font-size: 1rem;
    }
                
    .main-footer--social-links {
        ul {
            margin: 0;
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px 20px;
            justify-content: center;
            font-size: 2rem;
            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
                gap: 10px 10px;
            }
            li {
                margin: 0;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 3px;
                    font-size: 20px;
                    background-color: var(--theme--color);
                    color: var(--theme--background-color);
                    transition: background-color 200ms linear, color 200ms linear;
                    &:hover, &:focus {
                        opacity: 1;
                        background-color: var(--theme--btn--primary--bg-color--hover);
                        color: var(--theme--btn--primary--color--hover);
                    }
                }
            }
        }
    }
}
