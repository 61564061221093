.carousel {
    --carousel-indicators-radius: 5px;
    .carousel-indicators {
        bottom: 20px;
        margin: 0;
        padding: 0;
        gap: max(6px, var(--carousel-indicators-radius));
        li[data-bs-target] {
            width: calc(var(--carousel-indicators-radius) * 2);
            height: calc(var(--carousel-indicators-radius) * 2);
            background-color: white;
            border: 0;
            border-radius: var(--carousel-indicators-radius);
            margin: 0;
            padding: 0;
            transition: all 500ms ease-in-out;
            &.active {
                width: calc(var(--carousel-indicators-radius) * 4);
            }
        }
    }

    .carousel-control-prev, .carousel-control-next {
        --theme--btn--secondary--bg-color: #{$brand--yellow};
        --theme--btn--secondary--border-color: #{$brand--yellow};
        --theme--btn--secondary--color: #{$brand--black};
        --theme--btn--secondary--bg-color--hover: #{darken($brand--yellow, 10%)};
        --theme--btn--secondary--border-color--hover: #{$brand--white};
        --theme--btn--secondary--color--hover: #{$brand--black};
        position: absolute;
        top: 50%;
        width: fit-content;
        height: 50px;
        transform: translateY(-50%);
        pointer-events: none;
        opacity: 1;
        svg.fa-arrow-right, svg.fa-angle-right, svg.fa-arrow-left, svg.fa-angle-left {
            margin: 0;
        }
        button {
            pointer-events: all;
        }
    }
    .carousel-control-prev {
        left: calc(var(--edge-padding) / 2);
    }
    .carousel-control-next {
        right: calc(var(--edge-padding) / 2);
    }

}

.swiper-prev-btn, .swiper-next-btn {
    svg.fa-arrow-right, svg.fa-angle-right, svg.fa-arrow-left, svg.fa-angle-left {
        margin: 0;
    }
}

.swiper-pagination {
    --carousel-indicators-radius: 5px;
    .swiper-pagination-bullet {
        width: calc(var(--carousel-indicators-radius) * 2);
        height: calc(var(--carousel-indicators-radius) * 2);
        background-color: white;
        opacity: .5;
        border: 0;
        border-radius: var(--carousel-indicators-radius);
        margin: 0;
        padding: 0;
        transition: all 500ms ease-in-out;
        margin: 0 !important;
        &:not(:first-child) {
            margin-left: max(6px, var(--carousel-indicators-radius)) !important;
        }
        &.swiper-pagination-bullet-active {
            width: calc(var(--carousel-indicators-radius) * 4);
            background-color: white;
            opacity: 1;
        }
    }
}
