﻿.expander {
    .expander--header {
        background-color: $brand--sky-blue;
        padding: $expander--padding-top $expander--padding-right $expander--gap $expander--padding-left;
        --theme--link--color: #{$brand--white};
        --theme--link--color--hover: #{$brand--white};
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            padding: 0;
        }
        button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            border: 0;
            padding: 0;
            background-color: transparent;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            text-transform: inherit;
            line-height: inherit;
            color: var(--theme--link--color);
            text-align: left;
            .indicator {
                font-size: .75rem;
                margin-top: 0.25rem;
            }
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: var(--theme--link--text-decoration--hover);
            }
        }
    }
    .expander--content {
        padding: $expander--padding-top 0;
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    // when in an accordion

    .accordion & {
        &:not(:last-child) {
            border-bottom: 1px solid transparent;
        }
    }
}
