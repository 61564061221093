// ***********************************
// *****  package options modal  *****
// ***********************************

.modal#package-options-modal {
    .modal-dialog {
        max-width: 700px;
        &.modal-dialog-scrollable .modal-content {
            overflow-y: auto;
        }
        .package-options-modal--header {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            h1,
            .btn.close {
                flex: 1 1 auto;
            }
        }
        .package-option-modal--panel {
            .panel--date {
                line-height: 1.1;
                color: var(--theme--color--subtle);
            }
            .panel--title {
                font-size: 1.2rem;
                font-family: $headings-font-family;
                font-weight: 600;
                text-transform: uppercase;
            }
            .panel--price {
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: 1.1;
                color: var(--theme--accent--color);
            }
            &.unavailable {
                cursor: not-allowed;
                .panel--title {
                    opacity: 0.6;
                }
                .panel--price {
                    color: $brand--red;
                }
            }
        }
    }
}

// ***********************************
// *****  package options modal  *****
// ***********************************

.modal#js-seating-plan--modal {
    .modal-dialog {
        max-width: 700px;
        &.modal-dialog-scrollable .modal-content {
            overflow-y: auto;
        }
        .seat-option-modal--panel {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 1rem;
            p {
                margin-bottom: 0;
            }
            .panel--title {
                font-size: 1.2rem;
                font-family: $headings-font-family;
                font-weight: 600;
                text-transform: uppercase;
            }
            .panel--price {
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: 1.1;
                color: var(--theme--accent--color);
            }
            &.unavailable {
                cursor: not-allowed;
                .panel--title {
                    opacity: 0.6;
                }
                .panel--price {
                    color: $brand--red;
                }
            }
        }
    }
}

// *********************************
// *****  form number control  *****
// *********************************

.form-number-control {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    transition: opacity 0.5s ease;

    &.disabled {
        cursor: wait;
        pointer-events: none;
        opacity: 0.5;

        * {
            cursor: wait;
        }
    }

    button {
        user-select: none;
        display: flex;
        appearance: none;
        width: 1.8rem;
        height: 1.8rem;
        border: 0;
        padding: 0;
        border-radius: 3rem;
        justify-content: center;
        align-items: center;
        background-color: var(--theme--btn--secondary--bg-color);
        color: var(--theme--btn--secondary--color);
        &:not(:disabled):not(.disabled) {
            &:hover,
            &:focus {
                background-color: var(--theme--btn--secondary--bg-color--hover);
                color: var(--theme--btn--secondary--color--hover);
            }
        }
    }
    input[type="text"] {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: center;
    }
}

// ***********************************
// *****  product page elements  *****
// ***********************************

.product-gallery {
    --gap: 10px;
    --li-gap: 10px;
    --border-color: #{rgba($brand--black, 0.2)};
    display: flex;
    max-width: 300px;
    flex-direction: column;
    width: 100%;
    gap: var(--gap);
    position: sticky;
    top: var(--edge-padding);
    @include media-breakpoint-up(lg) {
        max-width: none;
    }
    figure {
        margin: 0;
        a {
            display: block;
            position: relative;
            &:after {
                display: block;
                content: "";
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid var(--border-color);
                pointer-events: none;
            }
            &.active {
                --border-color: #{$brand--black};
            }
        }
    }
    &--badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: -5px;
        right: -5px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        padding: 2px;
        margin: 0;
        background-color: $brand--red;
        color: $brand--white;
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        pointer-events: none;
    }
    &--main-image {
        appearance: none;
        display: block;
        width: 100%;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: zoom-in !important;
        aspect-ratio: 1;
    }
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--li-gap);
        li {
            display: block;
            margin: 0;
            padding: 0;
            width: calc((100% - (3 * var(--li-gap))) / 4); // 4 per row
            max-width: 80px;
            aspect-ratio: 1;
        }
    }
    &.enabled {
        &--main-image {
            cursor: pointer;
        }
    }
}

.product-gallery-carousel {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    position: sticky;
    top: var(--edge-padding);
    @include media-breakpoint-up(lg) {
        max-width: none;
    }
    &--badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: -5px;
        right: -5px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        padding: 2px;
        margin: 0;
        background-color: $brand--red;
        color: $brand--white;
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        pointer-events: none;
    }
    .swiper-pagination {
        position: relative;
        bottom: auto;
        left: auto;
    }
}

// ****************************
// *****  quantity table  *****
// ****************************

table.quantity-table {
    thead {
        th,
        td {
            padding-top: 0;
        }
    }
    tbody {
        th,
        td {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .form-number-control {
            margin-top: -0.4rem;
            margin-bottom: -0.4rem;
        }
        .quantity-table--price {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: var(--bs-body-font-size);
            text-transform: uppercase;
            color: var(--theme--accent--color);
        }
        .quantity-table--input {
            width: calc(120px + #{$table-cell-padding-x});
        }
        tr.quantity-table--unavailable {
            th {
                opacity: 0.6;
            }
            .quantity-table--price {
                color: $brand--red;
            }
        }
        tr.quantity-table--discount {
            .quantity-table--price {
                color: $brand--red;
            }
        }
    }
    &:not(.wide) {
        display: block;
        border-bottom: 1px solid var(--theme--rule--color);
        thead {
            display: none;
        }
        tbody {
            display: flex;
            flex-direction: column;
            .form-number-control {
                margin-top: 0;
                margin-bottom: 0;
            }
            tr {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border: 0 !important;
                th {
                    display: block;
                    width: 100%;
                    padding: $table-cell-padding-y 0;
                    flex: 0 0 auto;
                    border-top: 1px solid var(--theme--rule--color);
                    border-bottom: 0 !important;
                }
                td {
                    display: block;
                    width: 10%;
                    padding: 0 0 $table-cell-padding-y;
                    flex: 1 1 auto;
                    border: 0 !important;
                    &:last-child {
                        text-align: right;
                    }
                }
                &.quantity-table--unavailable {
                    td {
                        width: 100%;
                        text-align: left !important;
                    }
                }
            }
        }
    }
}

// ********************************
// *****  cost summary table  *****
// ********************************

table.cost-summary {
    --v-padding: 0.25rem;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: var(--bs-body-font-size);
    text-transform: uppercase;
    line-height: 1.2;
    th,
    td {
        font-size: var(--bs-body-font-size);
        line-height: 1.2;
        font-weight: 400;
        vertical-align: top;
    }
    th {
        padding: var(--v-padding) 1rem var(--v-padding) 0;
        color: var(--theme--accent--color);
    }
    td {
        padding: var(--v-padding) 0;
        text-align: right;
        white-space: nowrap;
    }
    tbody tr:last-child {
        th,
        td {
            padding-bottom: calc(var(--v-padding) * 2);
        }
    }
    tfoot {
        border-top: 1px solid var(--theme--rule--color);
        th,
        td {
            font-weight: 700;
            color: var(--theme--color);
            padding-top: calc(var(--v-padding) * 2);
        }
    }
}

// **************************************
// *****  membership details group  *****
// **************************************

.membership-details-group {
    &--item {
        .membership-details-group--item--header--summary {
            transition: opacity 300ms linear;
            will-change: opacity;
            strong.name-wrapper {
                font-size: 1rem;
                font-weight: $font-weight-base;
            }
        }
        &[aria-expanded="true"] {
            .membership-details-group--item--header--summary {
                opacity: 0;
                pointer-events: none;
            }
        }
        // inactive pre-edited items
        &[aria-expanded="false"]:not(.editable) {
            .membership-details-group--item--header {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
                &--summary {
                    display: none !important;
                }
            }
        }
    }
}

.membership-details-seats-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &--item {
        width: 100%;
    }
}

// **************************
// *****  seating plan  *****
// **************************

dl.seating-plan--key {
    display: block;
    list-style: none;
    padding: 0;
    --default-li-bottom-margin: 0.1em;
    dt,
    li {
        display: block;
        list-style: none;
        padding: 0 0 0 1.5rem;
    }
    dt {
        position: relative;
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;
        &:not(:first-child) {
            margin-top: 0.75em;
        }
        .seating-plan--key--colour {
            display: block;
            position: absolute;
            top: 0.4em;
            left: 0;
            width: 0.75em;
            height: 0.75em;
            margin-right: 0.5em;
            background-color: $brand--blue;
        }
    }
    li {
        font-size: 1rem;
        &:last-child {
            margin: 0;
        }
        small {
            color: var(--theme--color--subtle);
        }
    }
}

.seating-plan--container {
    & > iframe,
    & > .img-responsive {
        display: block;
        width: 100%;
        min-height: 320px;
        height: clamp(
            320px,
            50vh,
            calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height) + 60px))
        );
    }
}

// ****************************
// *****  basket listing  *****
// ****************************

.basket-listing {
    --thumbnail-width: 60px;
    --thumbnail-gap: 10px;
    &--title {
        background-color: $brand--sky-blue;
        color: $brand--white;
        padding: 0.75rem var(--panel--padding);
        margin: 0 0 10px 0;
        font-size: var(--bs-body-font-size);
        font-weight: 700;
        line-height: 1;
    }
    ol {
        display: block;
        list-style: none;
        padding: 0;
        li.basket-listing--item {
            display: flex;
            flex-direction: column;
            gap: 15px 30px;
            list-style: none;
            margin: 0;
            padding: var(--panel--padding);
            background-color: $brand--lightest-grey;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            .basket-listing--item--details {
                .basket-listing--item--details--heading {
                    margin-bottom: 0.75rem;
                }
                p {
                    margin-bottom: 0.25rem;
                }
                &.with-thumbnail {
                    position: relative;
                    .basket-listing--item--details--thumbnail {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: var(--thumbnail-width);
                    }
                    .basket-listing--item--details--heading {
                        min-height: var(--thumbnail-width);
                        padding-left: calc(var(--thumbnail-width) + var(--thumbnail-gap));
                    }
                }
            }
            .basket-listing--item--quantities {
                table {
                    --v-padding: 0.5rem;
                    --table-font-size: max(.875rem, 15px);
                    width: 100%;
                    font-family: $headings-font-family;
                    font-weight: 400;
                    font-size: var(--table-font-size);
                    text-transform: uppercase;
                    line-height: 1.2;
                    @include media-breakpoint-up(sm) {
                        --table-font-size: var(--bs-body-font-size);
                    }
                    th {
                        font-weight: 400;
                        padding-bottom: var(--v-padding);
                    }
                    thead th {
                        font-size: var(--table-font-size);
                        color: var(--theme--accent--color);
                        padding-right: .5em;
                        &:last-child {
                            text-align: right;
                            padding-right: 0;
                        }
                        abbr {
                            text-decoration: none;
                            cursor: default;
                        }
                    }
                    tbody {
                        th,
                        td {
                            border-top: 1px solid var(--theme--rule--color);
                            padding-top: var(--v-padding);
                            padding-bottom: var(--v-padding);
                            padding-right: .5em;
                        }
                        td:last-child {
                            text-align: right;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        --thumbnail-width: 100px;
        --thumbnail-gap: 20px;
    }

    @include media-breakpoint-up(lg) {
        --thumbnail-width: 120px;
        --thumbnail-gap: 20px;
        ol {
            li.basket-listing--item {
                flex-direction: row;
                justify-content: space-between;
                .basket-listing--item--details {
                    width: calc((100% - var(--panel--padding)) * 0.5);
                    &.with-thumbnail {
                        min-height: var(--thumbnail-width);
                        padding-left: calc(var(--thumbnail-width) + var(--thumbnail-gap));
                        .basket-listing--item--details--heading {
                            min-height: 0;
                            padding-left: 0;
                        }
                    }
                }
                .basket-listing--item--quantities {
                    width: calc((100% - var(--panel--padding)) * 0.5);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        --thumbnail-width: 140px;
        --thumbnail-gap: var(--panel--padding);
    }
}

// ****************************
// *****  donation panel  *****
// ****************************

.donation-panel {
    --default-li-bottom-margin: 0;
    ol {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            flex: 0 0 auto;
            margin: 0;
            padding: 0;
            list-style: none;
            input[type="checkbox"] + label {
                padding-left: 15px;
                padding-right: 15px;
                user-select: none;
                will-change: transform;
                .option--inner {
                    display: inline-block;
                    transform: translateX(-0.5rem);
                    transition: transform 200ms ease-in-out;
                    will-change: transform;
                }
                svg.fa-check {
                    color: #{$brand--yellow};
                    opacity: 0;
                    transform: scale(0);
                    transition: transform 200ms ease-in-out, opacity 200ms linear;
                    will-change: transform;
                }
            }
            input[type="checkbox"]:checked + label {
                .option--inner {
                    transform: translateX(0);
                }
                svg.fa-check {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}

// *******************
// *****  Forms  *****
// *******************

// sub form

.sub-form--bg-color {
    background: var(--theme--sub-form--bg-color);
}

.manual-address-entry {
    margin: 0 -#{map-get($spacers, 3)};
    .manual-address-entry--inner {
        background: var(--theme--sub-form--bg-color);
        margin-top: map-get($spacers, 4);
        padding: map-get($spacers, 4) map-get($spacers, 3);
    }
}

.sub-form {
    margin: 0 -#{map-get($spacers, 3)};
    .sub-form--inner {
        background: var(--theme--sub-form--bg-color);
        margin-top: map-get($spacers, 4);
        padding: map-get($spacers, 4) map-get($spacers, 3);
    }
}

// **********************
// *****  CHECKOUT  *****
// **********************

// flow stages indicator

.flow-stages {
    --stages--bg-color: #{$brand--white};
    --stages--border-color: #{$brand--sky-blue};
    --stages--label-color: #{$brand--grey};
    --stages--bg-color--active: #{$brand--white};
    --stages--border-color--active: #{$brand--green};
    --stages--label-color--active: #{$brand--black};
    --stages--bg-color--complete: #{$brand--blue};
    --stages--border-color--complete: #{$brand--blue};
    --stages--label-color--complete: #{$brand--grey};
    --default-li-bottom-margin: 0;
    ol {
        counter-reset: stage-counter;
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background-color: $brand--blue;
        }
        li {
            counter-increment: stage-counter;
            display: block;
            flex: 0 1 auto;
            position: relative;
            z-index: 2;
            margin: 0;
            padding: 0;
            text-align: center;
            .flow-stages--item {
                display: block;
                width: 100%;
                color: $body-color;
                font-family: $headings-font-family;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                text-transform: uppercase;
                color: var(--stages--label-color);
                &:before {
                    display: block;
                    content: "";
                    margin: 0 auto 8px;
                    width: 24px;
                    height: 24px;
                    background-color: var(--stages--bg-color);
                    border: 4px solid var(--stages--border-color);
                    border-radius: 50%;
                }
                .label {
                    color: var(--stages--label-color);
                }
                &.active {
                    font-weight: 600;
                    &:before {
                        background-color: var(--stages--bg-color--active);
                        border-color: var(--stages--border-color--active);
                    }
                    .label {
                        color: var(--stages--label-color--active);
                    }
                }
                &.complete {
                    &:before {
                        border-color: var(--stages--border-color--complete);
                        background: var(--stages--bg-color--complete)
                            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E")
                            no-repeat;
                        background-size: 75%;
                        background-position: center;
                    }
                    .label {
                        color: var(--stages--label-color--complete);
                    }
                }
            }
            a.flow-stages--item {
                text-decoration: none;
                &:hover,
                &:focus {
                    text-decoration: none;
                    &:before {
                        text-decoration: none !important;
                    }
                    .label {
                        text-decoration: underline;
                    }
                }
            }
        }

        @media (max-width: 500px) {
            .flow-stages--item:not(.active) .label {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            li {
                width: calc(100% / 4);
                .flow-stages--item {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
    }
}

// Checkout time remaining

#checkout-time-remaining {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    margin: 0;
    padding: 0 0.5rem;
    background-color: $color--lightest-blue;
    color: $brand--blue;
    font-size: 1rem;
    font-family: $headings-font-family;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    abbr {
        text-decoration: none;
        cursor: default;
    }
}

// sticky basket panel

.order-summary-column {
    @include media-breakpoint-up(lg) {
        position: relative;
        #order-summary {
            position: sticky;
            top: calc(var(--top-utility-bar--height) + var(--header--height) + 30px);
            transition: 300ms ease-in-out;
            will-change: transform;
            .header-hidden & {
                top: 30px;
            }
        }
    }
}

// delivery listing

.delivery-listing {
    display: flex;
    flex-direction: column;
    border-top: 1px var(--theme--rule--color) solid;
    --default-li-bottom-margin: 0;
    & > li {
        padding: 15px 0;
        border-bottom: 1px var(--theme--rule--color) solid;
    }
    &--item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        @include media-breakpoint-up(md) {
            gap: 15px;
        }
        &--image {
            width: 60px;
            flex: 0 0 auto;
            aspect-ratio: 1;
            @include media-breakpoint-up(md) {
                width: 80px;
            }
        }
        &--details {
            flex: 1 1 auto;
        }
    }
}

// internal-packages-listing

.internal-packages-listing {
    user-select: none;
    &--results {
        &--item {
            transform: scale(1);
            opacity: 1;
            will-change: transform;
            transition: transform 300ms ease-in-out, opacity 300ms linear;
            &.fading {
                transform: scale(0.9);
                opacity: 0;
            }
            &.hidden {
                display: none !important;
            }
            button[data-bs-toggle="collapse"] {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1rem;
                text-align: left;
                .title {
                    margin: 0;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    line-height: $headings-line-height;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    font-weight: 600;
                    flex: 1 1 auto;
                    margin-right: auto;

                }
                .from-price {
                    flex: 0 0 auto;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    line-height: $headings-line-height;
                    text-transform: uppercase;
                    color: var(--theme--accent--color);
                }
                .indicator {
                    flex: 0 0 auto;
                }
                &:hover, &:focus {
                    .title {
                        text-decoration: underline;
                    }
                }
            }
            .quantities {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                & > .panel {
                    background: #{lighten($color--lightest-blue, 5%)} !important;
                    width: 100%;
                    flex: 0 0 auto;
                }
                &:has(.panel:nth-child(2)) {
                    & > .panel {
                        width: 100%;
                        @include media-breakpoint-up(md) {
                            width: calc((100% - 20px) / 2);
                        }
                    }
                }
                &:has(.panel:nth-child(3)) {
                    & > .panel {
                        width: 100%;
                        @include media-breakpoint-up(md) {
                            width: calc((100% - 20px) / 2);
                        }
                        @include media-breakpoint-up(xl) {
                            width: calc((100% - 40px) / 3);
                        }
                    }
                }
            }
        }
    }
    &--summary {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        h2 {
            color: var(--theme--accent--color);
            font-size: 1.2rem;
        }
        p {
            margin: 1rem 0 0;
        }
        strong {
            font-family: $headings-font-family;
            font-weight: 600;
            line-height: $headings-line-height;
            text-transform: uppercase;
            font-size: 1.2rem;
        }
        a {
            strong {
                color: var(--theme--color);
            }
            &:hover, &:focus {
                strong {
                    color: var(--theme--link--color--hover);
                }
            }
        }
        .subtitle {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            line-height: $headings-line-height;
            text-transform: uppercase;
            font-size: 1rem;
            color: var(--theme--accent--color);
        }
    }
}
