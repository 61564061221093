// This is needed for lazy sizes to autocompute width

img[data-sizes="auto"] {
    display: block;
}

.img-responsive {
    @extend .img-fluid;
    width: 100%;
}

.img-scale-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $brand--black;
    img {
        display: block;
        transform: scale(1.005);
        transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
        will-change: transform;
        min-width: 10px; // lazy-loading bug-fix
    }
    a:hover & img, a:focus & img, & a:hover img, & a:focus img, &:hover img {
        transform: scale(1.05);
        transition-duration: 500ms;
    }
}

// fade in when lazy loaded

.lazy-fade-in:not(.hunt-watch) {
    opacity: 0;
    &.lazyloaded {
        opacity: 1;
        transition: opacity 1s linear, transform 300ms ease-out;
    }
}

.lazy-scale-fade-in:not(.hunt-watch) {
    opacity: 0;
    transform: scale(1.2);
    &.lazyloaded {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s linear, transform 1s ease-out;
    }
}

// fade in when lazy loaded + enters viewport

.lazy-fade-in.hunt-watch {
    opacity: 0;
    &.lazyloaded.hunt-in {
        opacity: 1;
        transition: opacity 1s linear, transform 300ms ease-out;
    }
}

.lazy-scale-fade-in.hunt-watch {
    opacity: 0;
    transform: scale(1.2);
    &.lazyloaded.hunt-in {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s linear, transform 1s ease-out;
    }
}
