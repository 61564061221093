:root {
    --default-li-bottom-margin: 0.4em;
}

main ul:not([class]), main ol:not([class]) {
    li:not(:last-child) {
        margin-bottom: var(--default-li-bottom-margin);
    }
}

// themed list global styles

ul.themed-list, ol.themed-list, ul.icon-list, ul.tick-list, ul.feature-list {
    display: block;
    list-style: none;
    padding: 0;
    li {
        list-style: none;
        display: block;
        position: relative;
        margin: 0 0 var(--default-li-bottom-margin);
        padding: 0 0 0 1.75em;
        &:before {
            display: block;
            content: '';
            position: absolute;
        }
        &:last-child {
            margin: 0;
        }
    }
}


// unordered list

ul.themed-list {
    li {
        &:before {
            top: 0.5em;
            left: 0;
            width: .5em;
            height: .5em;
            border-radius: 0.25em;
            background-color: var(--theme--decor--color);
        }
    }
}

// ordered list

ol.themed-list {
    counter-reset: list-number;
    li {
        counter-increment: list-number;
        &:before {
            display: flex;
            justify-content: center;
            align-items: center;
            content: counter(list-number);
            top: 0.25em;
            left: 0;
            width: 1.6em;
            height: 1.6em;
            border-radius: 50%;
            background-color: var(--theme--decor--color);
            color: var(--theme--color--emphasis--invert);
            font-weight: $font-weight-bold;
            font-size: 0.7em;
        }
    }
}

// icon list

ul.icon-list {
    li {
        &:before {
            display: none;
            content: none;
        }
        i, svg {
            color: var(--theme--decor--color);
        }
    }
    &:not(.icon-list--inline-features) {
        text-align: left !important;
        li {
            i, svg {
                position: absolute;
                top: 0.2em;
                left: -0.1em;
            }
        }
    }
    &.icon-list--inline-features {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 15px 30px;
        align-items: stretch;
        li {
            margin: 0;
            padding: 0;
            flex: 0 1 auto;
            display: flex;
            min-height: 100%;
            flex-direction: column;
            gap: .4rem;
            align-items: center;
            text-align: center;
            line-height: 1.4;
            i, svg {
                font-size: 1.6rem;
            }
            & > small {
                display: block;
                max-width: 180px;
            }
        }
    }
}

// tick-list (purely class based)

ul.tick-list {
    text-align: left !important;
    li {
        &:before {
            top: 0.2em;
            left: -0.1em;
            width: 1em;
            height: 1em;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23d7282d' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            @supports(mask-image: url(test.svg)) {
                background: var(--theme--decor--color);
                mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23d7282d' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
                mask-size: contain;
                mask-repeat: no-repeat;
            }
        }
    }
}

// feature-list

ul.feature-list {
    text-align: left !important;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0;
    li {
        width: 100%;
        padding-left: 2.4rem;
        margin: 0;
        &:before {
            top: -0.15rem;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23ffc300' d='M374.6 164L315.2 51.4 288.1 0 260.9 51.4 201.5 164 76.2 185.7l-57.3 9.9 40.5 41.7 88.7 91.2L130 454.4 121.7 512l52.2-25.7 114.2-56.1 114.2 56.1L454.4 512l-8.3-57.6L428 328.5l88.7-91.2 40.5-41.7-57.3-9.9L374.6 164zm84.8 63.4l-65.8 67.7L377.2 312l3.4 23.4L394 428.8l-84.7-41.7-21.2-10.4-21.2 10.4-84.7 41.7 13.4-93.4L199 312l-16.4-16.9-65.8-67.7 93-16.1 23.3-4 11-20.9 44.1-83.5 44.1 83.5 11 20.9 23.3 4 93 16.1z'/%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            @supports(mask-image: url(test.svg)) {
                background: $brand--yellow;
                mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23ffc300' d='M374.6 164L315.2 51.4 288.1 0 260.9 51.4 201.5 164 76.2 185.7l-57.3 9.9 40.5 41.7 88.7 91.2L130 454.4 121.7 512l52.2-25.7 114.2-56.1 114.2 56.1L454.4 512l-8.3-57.6L428 328.5l88.7-91.2 40.5-41.7-57.3-9.9L374.6 164zm84.8 63.4l-65.8 67.7L377.2 312l3.4 23.4L394 428.8l-84.7-41.7-21.2-10.4-21.2 10.4-84.7 41.7 13.4-93.4L199 312l-16.4-16.9-65.8-67.7 93-16.1 23.3-4 11-20.9 44.1-83.5 44.1 83.5 11 20.9 23.3 4 93 16.1z'/%3E%3C/svg%3E");
                mask-size: contain;
                mask-repeat: no-repeat;
            }
        }
        h1, h2, h3, h4, h5, h6 {
            font-family: $headings-font-family !important;
            font-weight: $headings-font-weight !important;
            line-height: $headings-line-height !important;
            text-transform: uppercase !important;
            font-size: $font-size-base !important;
        }
        & > *:last-child {
            margin-bottom: 0 !important;
        }
    }
    @include media-breakpoint-up(lg) {
        &.feature-list--two-columns {
            flex-direction: row;
            flex-wrap: wrap;
            li {
                width: calc((100% - 3rem) / 2);
            }
        }
    }
}
