.hide-border {
    border-color: transparent;
    background-clip: border-box;
}

.form-check {
    clear: both;
    display: flex;
    gap: 0.5rem;
    .form-check-input[type="checkbox"] {
        width: 1.4rem;
        height: 1.4rem;
        flex: 0 0 auto;
        &:checked {
            background-color: var(--theme--form--checked--bg-color);
            border-color: var(--theme--form--checked--border-color);
            background-image: var(--theme--form--checkbox--indicator);
            background-size: 75% 95%;
        }
        &[type="checkbox"] {
            border-radius: 0;
        }
    }
    .form-check-label {
        width: 100%;
        flex: 0 1 auto;
        color: var(--theme--color);
        padding-top: 0.2rem;
    }
}
.form-check .form-check-input[type="radio"] {
    width: 1.4rem;
    height: 1.4rem;
    &:checked {
        background-color: var(--theme--form--checked--bg-color);
        border-color: var(--theme--form--checked--border-color);
        background-image: var(--theme--form--radio--indicator);
    }
}

.form-label {
    font-family: $headings-font-family;
    font-weight: 400;
    line-height: $headings-line-height;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--theme--accent--color);
}

.mandatory-field-indicator {
    color: $brand--red;
    font-size: 1rem;
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    text-transform: none;
    margin-left: .2rem;
}

input[readonly].form-control {
    cursor: not-allowed;
    color: var(--theme--color--subtle);
}

span.field-validation-valid {
    visibility: hidden;
}

span.field-validation-error, .validation-summary-errors li {
    visibility: visible;
    margin-top: 0.25rem;
    font-family: "klavika-web", "Times New Roman", Times, serif;
    font-size: 0.875em;
    color: rgba(var(--bs-danger-rgb) ) !important;
}
