// nav-jumplist
.nav-jumplist {
    border-top: 1px solid var(--theme--rule--color);
    li {
        padding-top: 1rem;
        margin-bottom: 1rem;
        &:not(:first-child) {
            border-top: 1px solid var(--theme--rule--color);
        }
        &:first-child {
            margin-bottom: 0;
        }
        h1, h2, h3, h4, h5, h6 {
            color: var(--theme--accent--color);
        }
    }
}

// taglist

ul.taglist {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    --default-li-bottom-margin: 0;
    li {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        a, span {
            display: inline-flex;
            align-items: center;
            border: 1px solid var(--theme--color--subtle);
            background-color: transparent;
            color: var(--theme--color--subtle);
            padding: 3px 6px;
            min-height: 20px;
            border-radius: 0;
            font-family: $headings-font-family;
            font-weight: 600;
            line-height: 1;
            font-size: 12px;
            text-transform: uppercase;
            white-space: nowrap;
        }
        a {
            &:hover, :focus {
                border-color: var(--theme--link--color--hover);
                background-color: var(--theme--link--color--hover);
                color: white;
                text-decoration: none;
            }
        }
    }
}

// listing item

.listing-item {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    p {
        margin: 0;
    }
    &--image {
        position: relative;
        .listing-item--labels {
            display: flex;
            justify-content: flex-end;
            gap: .5rem;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            padding: .5rem;
            pointer-events: none;
            user-select: none;
            .label {
                display: inline-block;
                background-color: $brand--yellow;
                color: $brand--black;
                font-family: $headings-font-family;
                font-weight: 600;
                line-height: $headings-line-height;
                text-transform: uppercase;
                font-size: max(12px, 0.8rem);
                padding: 0.25rem 0.5rem;
            }
        }
        .listing-item--corner-flash {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100px;
            height: 100px;
            margin: 0;
            overflow: hidden;
            pointer-events: none;
            user-select: none;
            strong {
                display: block;
                position: absolute;
                left: 100%;
                bottom: 0;
                width: 150px;
                height: 71px;
                margin-left: -75px;
                background-color: $brand--red;
                color: $brand--white;
                text-align: center;
                transform-origin: 50% 100%;
                transform: rotate(-45deg);
                font-family: $headings-font-family;
                font-weight: 600;
                line-height: $headings-line-height;
                text-transform: uppercase;
                font-size: max(12px, 0.8rem);
                padding: 0.25rem 38px 0;
            }
        }
    }
    &--title {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        margin-top: .35rem;
        font-size: 1rem;
        h2, h3, h4, h5, h6, .price {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 600;
            flex: 1 1 auto;
        }
        .price {
            flex: 0 0 auto;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            line-height: $headings-line-height;
            text-transform: uppercase;
            color: var(--theme--accent--color);
        }
        &.bottom-rule {
            padding-bottom: .75rem;
            border-bottom: 1px solid var(--theme--rule--color);
        }
    }
    &--summary {
        font-size: 1rem;
    }
    &--tags {
        font-size: 0;
    }
    &--cta {
        margin-top: 10px;
        .cta {
            display: flex;
            gap: .75rem 1rem;
            flex: 0 0 auto;
            flex-wrap: wrap;
        }
        .listing-item--quantities {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px
            p {
                margin: 0;
            }
        }
    }
    &.listing-item--unavailable {
        cursor: not-allowed;
        .listing-item--image img {
            opacity: 0.6;
        }
        .listing-item--title,
        .listing-item--summary,
        .listing-item--tags,
        .listing-item--cta {
            opacity: 0.6;
        }
    }
}

// filter-listing
.filter-listing {
    &--filters {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        & > * {
            flex: 0 1 auto;
        }
        .btn {
            border-width: $input-border-width;
        }
        &--filter.dropdown {
            button[data-bs-toggle="dropdown"] {
                &:after {
                    display: none;
                }
                .indicator {
                    will-change: color;
                    transition: color 200ms linear;
                    font-size: .5rem;
                    vertical-align: middle;
                    color: currentColor;
                    will-change: color;
                    transition: color 200ms linear;
                    font-size: .5rem;
                    vertical-align: middle;
                }
                &[aria-expanded="true"] {
                    .indicator {
                        color: $brand--sky-blue;
                    }
                }
            }
            ul.dropdown-menu {
                min-width: 100%;
                max-height: calc(var(--vpHeight) - 100px);
                overflow-y: auto;
                &.show {
                    display: flex;
                    flex-direction: column;
                }
                li.dropdown-header {
                    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
                }
                li.filter-tag {
                    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
                    font-size: 1rem;
                    line-height: 1.2;
                    .form-check {
                        margin: 0;
                        white-space: nowrap;
                        &-input {
                            margin-top: 0.1em;
                        }
                    }
                }
            }
        }
        .filter-listing--sorting {
            display: flex;
            align-items: center;
            gap: .5rem;
            margin-left: auto;
        }
    }
    &--active-tags {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 0.75rem;
        min-height: 2rem;
        margin: 0;
        padding: 0;
        list-style: none;
        --default-li-bottom-margin: 0;
        li {    
            list-style: none;
            display: inline-flex;
            gap: 0.4em;
            margin: 0;
            align-items: center;
            border: 1px solid var(--theme--color--subtle);
            color: var(--theme--color--subtle);
            padding: 3px 6px;
            min-height: 20px;
            border-radius: 0;
            font-family: $headings-font-family;
            font-weight: 600;
            line-height: 1;
            font-size: 12px;
            text-transform: uppercase;
            .btn-link {
                font-size: 1rem;
                margin: -3px -3px -3px 0;
            }
        }
    }
    &--results {
        --default-li-bottom-margin: 0;
        li {
            display: block;
            &[aria-hidden="true"] {
                display: none;
            }
        }
    }
}

// product-listing
.product-listing {
    .listing-item {
        &--image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: var(--panel--padding);
            box-shadow: 0 3px 10px rgba(black, .2);
            figure, a {
                width: 100%;
                margin: 0;
            }
            img {
                width: 100%;
                height: auto;
                max-height: 240px;
                object-fit: contain;
                cursor: zoom-in;
            }
        }
    }
}

// stages-layout
.stages-layout {
    display: block;
    list-style: none;
    counter-reset: li-count;
    padding: 0;
    --progress-width: 60px;
    --progress-roundel-radius: 22px;
    --padding-bottom: 60px;
    @include media-breakpoint-up(md) {
        --progress-width: 80px;
        --progress-roundel-radius: 30px;
        --padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
        --progress-width: 100px;
        --progress-roundel-radius: 35px;
        --padding-bottom: 90px;
    }
    .stages-layout--stage {
        list-style: none;
        counter-increment: li-count;
        display: block;
        position: relative;
        margin: 0;
        padding: 0 0 var(--padding-bottom) var(--progress-width);
        pointer-events: none;
        .stages-layout--stage--progress {
            position: absolute;
            top: 0;
            left: 0;
            width: var(--progress-width);
            height: 100%;
            .roundel {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: calc(2 * var(--progress-roundel-radius));
                height: calc(2 * var(--progress-roundel-radius));
                border-radius: var(--progress-roundel-radius);
                background-color: var(--theme--btn--secondary--bg-color);
                color: var(--theme--btn--secondary--color);
                transform: scale(0);
                will-change: transform;
                &:before {
                    display: inline;
                    content: counter(li-count);
                }
            }
            .line {
                display: block;
                position: absolute;
                top: calc(2 * var(--progress-roundel-radius));
                left: calc(var(--progress-roundel-radius) - 1px);
                width: 2px;
                height: calc(100% - calc(2 * var(--progress-roundel-radius)));
                z-index: 1;
                &--inner {
                    display: block;
                    background: linear-gradient(180deg, var(--theme--btn--secondary--bg-color) 0%, var(--theme--btn--secondary--bg-color) 25%, transparent 25%, transparent 75%, var(--theme--btn--secondary--bg-color) 75%, var(--theme--btn--secondary--bg-color) 100%);
                    background-size: 20px 20px;
                    background-position: 0 0;
                    width: 2px;
                    height: 0;
                    will-change: transform;
                }
            }
        }
        .stages-layout--stage--content {
            padding-top: calc(var(--progress-roundel-radius) - .6rem);
            opacity: 0;
            will-change: opacity;
            .cta {
                display: flex;
                gap: 1rem;
                flex: 0 0 auto;
                margin: 0;
            }
        }
        &:last-child {
            padding-bottom: 0;
        }
        &.reveal {
            pointer-events: all;
            .stages-layout--stage--progress .roundel {
                transform: scale(1);
            }
            .stages-layout--stage--content {
                opacity: 1;
            }
            .stages-layout--stage--progress .line .line--inner {
                height: 100%;
            }
            &.animate-reveal {
                .stages-layout--stage--progress .roundel {
                    transition: transform 500ms ease-in-out;
                }
                .stages-layout--stage--content {
                    transition: opacity 1s linear;
                    transition-delay: 500ms;
                }
                .stages-layout--stage--progress .line .line--inner {
                    transition: height 1s ease-in-out;
                    transition-delay: 500ms;
                }
            }
        }
    }
}

// wide-image-text-layout--listing

.wide-image-text-layout--listing {
    --default-li-bottom-margin: 0;
    border-top: 1px solid var(--theme--rule--color);
    & > li {
        padding: 3rem 0;
        border-bottom: 1px solid var(--theme--rule--color);
    }
    &.hide-top-bottom-rules {
        border-top: 0;
        & > li:first-child {
            padding-top: 0;
        }
        & > li:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}

// pagination
.pagination {
    align-items: stretch;
    @include media-breakpoint-up(md) {
        --bs-pagination-font-size: max(14px, 0.875rem);
    }
    .page-item {
        margin: 0;
        border: 0;
        min-height: 100%;
        .page-link {
            min-height: 100%;
            color: var(--theme--link--color);
            background-color: transparent;
            border-color: var(--theme--rule--color);
            font-family: $headings-font-family;
            font-weight: 600;
            line-height: 1;
            &:hover, &:focus {
                color: var(--theme--btn--secondary--color--hover);
                background-color: var(--theme--btn--secondary--bg-color--hover);
                border-color: var(--theme--btn--secondary--border-color--hover);
            }
        }
        &:not(:first-child) .page-link {
            border-left: 0;
        }
        &.active {
            .page-link {
                color: var(--theme--color--emphasis--invert);
                border-color: var(--theme--color);
                background-color: var(--theme--color);
                &:hover, &:focus {
                    color: var(--theme--color--emphasis--invert);
                    background-color: var(--theme--color);
                    border-color: var(--theme--color);
                }
            }
        }
        &.disabled {
            .page-link {
                opacity: 0.35;
                cursor: not-allowed;
            }
        }
    }
}

// sponsor-logo-listing

ul.sponsor-logo-listing {
    --img-max-width: 210px;
    --img-max-height: 150px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    margin: 0;
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(md) {
        --img-max-width: 280px;
        --img-max-height: 200px;
    }
    li {
        display: contents;
        figure {
            display: contents;
            img {
                width: auto;
                height: auto;
                width: var(--img-max-width);
                object-fit: contain;
                will-change: transform;
                transition: transform 500ms ease-in-out;
                &.portrait {
                    width: auto;
                    height: var(--img-max-height);
                }
            }
            a {
                display: block;
                width: fit-content;
                height: fit-content;
                &:hover, &:focus {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

