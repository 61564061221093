// Klavika (temp served from Adobe Cloud account)

@import url('//use.typekit.net/oli1onf.css');

// Klavika Basic Regular
// font-family: "klavika-web", sans-serif;
// font-weight: 400;
// // font-style: normal;

// Klavika Basic Medium
// font-family: "klavika-web", sans-serif;
// font-weight: 600;
// font-style: normal;

// Klavika Basic Bold
// font-family: "klavika-web", sans-serif;
// font-weight: 700;
// font-style: normal;

@import url('//fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap');
