:root {
    --panel--padding: #{map-get($panel--padding, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --panel--padding: #{map-get($panel--padding, $breakpoint)};
        }
    }
}

.panel-padding {
    padding: var(--panel--padding); // default padding
}

.panel {
    display: block;
    padding: var(--panel--padding); // default padding
    & > *:last-child {
        margin-bottom: 0;
    }
}

.panel--outline {
    border: 1px solid var(--theme--rule--color);
}

.panel-banner-heading {
    background-color: $brand--sky-blue;
    color: $brand--white;
    padding: .75rem var(--panel--padding);
    margin: 0 0 10px 0;
    font-size: var(--bs-body-font-size);
    font-weight: 700;
    line-height: 1;
}

.icon-panel {
    .icon-panel--icon {
        color: var(--theme--icon--color);
        font-size: 2rem;
        margin: 0 0 .5rem 0;
    }
}

// poster-panel
.poster-panel {
    position: relative;
    display: flex;
    align-items: flex-end;
    &:before {
        display: block;
        content: '';
        width: 1px;
        margin-right: -1px;
        height: 0;
        padding-bottom: 120%;
        pointer-events: none;
    }
    .img-scale-wrapper {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--content {
        width: 100%;
        position: relative;
        z-index: 2;
        padding: calc(2 * var(--panel--padding)) var(--panel--padding) var(--panel--padding);
        background-image: linear-gradient(to top, rgba(black, 0.5) 0%, rgba(black, 0) 100%) !important;
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    @supports(aspect-ratio: 4/5) {
        &:before {
            display: none;
            content: none;
        }
        aspect-ratio: 4/5;
    }
    &:hover, &:focus {
        text-decoration: none !important;
        .poster-panel--content {
            color: $brand--white;
            h2 {
                text-decoration: underline !important;
                text-decoration-color: $brand--white;
            }
        }
    }
}
