@keyframes video-loading-sprite {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

.hero {
    display: block;
    position: relative;
    background-color: $brand--blue;
    --img--min-height: #{map-get($hero--img--min-heights, xs)};
    --img--max-height: #{map-get($hero--img--max-heights, xs)};
    --img--height: #{map-get($hero--img--heights, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --img--min-height: #{map-get($hero--img--min-heights, $breakpoint)};
            --img--max-height: #{map-get($hero--img--max-heights, $breakpoint)};
            --img--height: #{map-get($hero--img--heights, $breakpoint)};
        }
    }
    .hero--layout {
        display: contents;
    }
    .hero--image {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: var(--img--min-height);
        max-height: var(--img--max-height);
        height: var(--img--height);
        overflow: hidden;
        background-color: $brand--black;
        overflow: hidden;
        margin: 0;
        picture {
            width: 100%;
        }
        img {
            display: block;
            width: 100%;
            height: calc(100% + 1px);
            object-fit: cover;
            &.img-load-watcher {
                opacity: 0;
                will-change: opacity;
                transition: opacity 1s linear;
                &.loaded {
                    opacity: 1;
                }
            }
        }
    }
    .hero--content {
        width: 100%;
    }

    .carousel {
        width: 100%;
        .carousel-controls {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            min-height: var(--img--min-height);
            max-height: var(--img--max-height);
            height: var(--img--height);
            pointer-events: none;
            .carousel-indicators {
                pointer-events: all;
            }
            .carousel-control-prev, .carousel-control-next {
                top: auto;
                bottom: 10px;
                transform: none;
            }
        }
    }

    // deeper version
    &.hero--deeper {
        --img--min-height: #{map-get($hero--img--deeper--min-heights, xs)};
        --img--max-height: #{map-get($hero--img--deeper--max-heights, xs)};
        --img--height: #{map-get($hero--img--deeper--heights, xs)};
        // loop through each breakpoint
        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                --img--min-height: #{map-get($hero--img--deeper--min-heights, $breakpoint)};
                --img--max-height: #{map-get($hero--img--deeper--max-heights, $breakpoint)};
                --img--height: #{map-get($hero--img--deeper--heights, $breakpoint)};
            }
        }
    }

    // Hero video stuff

    .hero--layout .hero--video {
        display: block;
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: var(--img--min-height);
        max-height: var(--img--max-height);
        height: var(--img--height);
        overflow: hidden;
        &.vimeo > iframe { // bug fix for vimeo embed
            display: none;
        }
        .hero--video-inner { // should remain as 16:9 ratio container and resize to emulate object-fit: cover
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            --transition: opacity 1s linear;
            .hero--poster-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                opacity: 1;
                transition: var(--transition);
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transition: opacity 500ms linear;
                    &.loaded {
                        opacity: 1;
                    }
                }
            }
            iframe {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: var(--transition);
            }
            &.reveal {
                .hero--poster-image {
                    opacity: 0;
                }
                iframe {
                    opacity: 1;
                }
            }
            &.has-poster {
                --transition: none;
                iframe {
                    opacity: 1;
                }
                &.crossfade-poster {
                    --transition: opacity 1s linear;
                }
            }
        }
        .hero--video-loading-sprite {
            position: absolute;
            z-index: 4;
            top: 15px;
            left: calc(50% - 30px);
            width: 60px;
            height: 3px;
            overflow: hidden;
            opacity: 1;
            will-change: opacity;
            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: calc(100% + 30px);
                height: 100%;
                background: linear-gradient(to right, rgba(black, 0.4) 0%, rgba(black, 0.4) 50%, white 50%, white 100%) repeat-x;
                background-size: 20px 100%;
                will-change: transform;
                animation: video-loading-sprite 1s infinite linear;
            }
            &.hidden {
                opacity: 0;
                transition: opacity 500ms linear;
            }
        }
        .hero--video--control {
            display: flex;
            flex-direction: row;
            gap: 4px;
            position: absolute;
            z-index: 4;
            top: 15px;
            right: 15px;
            opacity: 0;
            transition: opacity 500ms linear;
            pointer-events: none;
            &.reveal {
                opacity: 1;
                pointer-events: all;
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                appearance: none;
                width: 30px;
                height: 30px;
                font-size: 14px;
                line-height: 14px;
                margin: 0;
                padding: 0;
                border: 0;
                background-color: rgba(black, .4);
                color: white;
                cursor: pointer;
                &:not(:disabled):focus, &:not(:disabled):hover {
                    background-color: $dark;
                    color: white;
                }
                .hero--video--control--play, .hero--video--control--pause, .hero--video--control--sound-on, .hero--video--control--sound-off {
                    display: inline;
                    &[aria-hidden="true"] {
                        display: none;
                    }
                }
            }
        }
    }

    // when inside fullscreen-top-section
    
    .fullscreen-top-section & {
        display: flex;
        flex: 1 1 auto;
        min-height: var(--img--min-height);
        height: 100%;
        align-items: stretch;

        & > .hero--layout { // not carousels
            display: flex;
            flex-wrap: wrap;
            min-height: 100%;
            align-items: stretch;
            width: 100%;
            .hero--image, .hero--video {
                min-height: var(--img--min-height);
                max-height: var(--img--max-height);
                height: var(--img--height);
            }
        }

        .carousel .hero--image {
            min-height: var(--img--min-height);
            max-height: var(--img--max-height);
            height: var(--img--height);
        }

        .hero--layout .hero--content .hero--content--inner {
            padding: var(--edge-padding);
            & > *:last-child {
                margin-bottom: 0;
            }

            &.with-countdown {
                .hero--content--countdown-panel {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr);
                    grid-template-rows: auto;
                    .hero--content--countdown-panel--title {
                        grid-column: 1;
                        grid-row: 1;
                        padding: 15px var(--panel--padding);
                        border-bottom: 1px solid var(--theme--rule--color);
                    }
                    .hero--content--countdown-panel--countdown-area {
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                        padding: 15px var(--panel--padding);
                        border-bottom: 1px solid var(--theme--rule--color);
                        .hero--content--countdown-panel--countdown-area--countdown {
                            width: 100%;
                        }
                        .hero--content--countdown-panel--countdown-area--sponsor {
                            width: 100%;
                            img {
                                max-width: 120px;
                            }
                        }
                    }
                    .hero--content--countdown-panel--sponsor-title {
                        grid-column: 1;
                        grid-row: 3;
                        padding: 15px var(--panel--padding);
                        border-bottom: 1px solid var(--theme--rule--color);
                    }
                    .hero--content--countdown-panel--sponsor-logo {
                        grid-column: 1;
                        grid-row: 4;
                        padding: 15px var(--panel--padding);
                        img {
                            max-width: 180px;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .carousel {
                .carousel-controls {
                    min-height: 0;
                    max-height: none;
                    height: 100%;
                }
                .carousel-inner, .carousel-item {
                    width: 100%;
                    height: 100%;
                }
            }

            .hero--layout {
                .hero--content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 3;
                    width: 100%;
                    min-height: 100%;
                    padding: 120px 0 60px;
                    pointer-events: none;
                    &.content-left {
                        justify-content: flex-start;
                    }
                    &.content-right {
                        justify-content: flex-end;
                    }
                    &.content-top {
                        align-items: flex-start;
                    }
                    &.content-bottom {
                        align-items: flex-end;
                    }
                    .hero--content--inner {
                        padding-top: 0;
                        padding-bottom: 0;
                        & > * {
                            pointer-events: all;
                        }

                        &.with-countdown {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: center;
                            min-height: 100%;
                            .hero--content--countdown-panel {
                                max-width: 900px;
                                margin: 0 auto;
                                grid-template-columns: minmax(0, 1fr) 240px;
                                .hero--content--countdown-panel--title {
                                    grid-column: 1 / 2;
                                    grid-row: 1;
                                    border-right: 1px solid var(--theme--rule--color);
                                }
                                .hero--content--countdown-panel--countdown-area {
                                    flex-direction: row;
                                    align-items: center;
                                    border-bottom: 0;
                                    border-right: 1px solid var(--theme--rule--color);
                                    .hero--content--countdown-panel--countdown-area--countdown {
                                        flex: 1 1 auto;
                                        order: 2;
                                    }
                                    .hero--content--countdown-panel--countdown-area--sponsor {
                                        flex: 0 0 160px;
                                        width: 160px;
                                        order: 1;
                                    }
                                }
                                .hero--content--countdown-panel--sponsor-title {
                                    grid-column: 2 / 3;
                                    grid-row: 1;
                                }
                                .hero--content--countdown-panel--sponsor-logo {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    grid-column: 2 / 3;
                                    grid-row: 2;
                                }
                            }
                        }

                    }
                }
                .hero--image, .hero--video {
                    display: flex;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    max-height: none;
                }
                .hero--tint {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    background-color: black;
                    pointer-events: none;
                    opacity: 0;
                    &.tint-90 {
                        opacity: .9;
                    }
                    &.tint-80 {
                        opacity: .8;
                    }
                    &.tint-70 {
                        opacity: .7;
                    }
                    &.tint-60 {
                        opacity: .6;
                    }
                    &.tint-50 {
                        opacity: .5;
                    }
                    &.tint-40 {
                        opacity: .4;
                    }
                    &.tint-30 {
                        opacity: .3;
                    }
                    &.tint-20 {
                        opacity: .2;
                    }
                    &.tint-10 {
                        opacity: .1;
                    }
                }
                &.clickable.enabled {
                    cursor: pointer;
                }
            }

            .carousel {
                height: auto;
                width: 100%;
            }
        }

    }
}
