h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--theme--color--emphasis);
    margin-bottom: max(1rem, 0.5em);
    &.bottom-rule {
        width: fit-content;
        padding-bottom: max(1rem, 0.45em);
        border-bottom: 1px solid var(--theme--rule--color);
    }
    a {
        color: currentColor !important;
        &:hover, &:focus {
            color: var(--theme--link--color--hover) !important;
        }
    }
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
    strong, b {
        font-weight: $headings-font-weight;
    }
}
h5, h6, .h5, .h6 {
    font-family: $font-family-base;
    font-weight: 600; // Medium
    text-transform: none;
    strong, b {
        font-weight: 700;
    }
}
.display-1, .display-2, .display-3, .display-4 {
    font-weight: 600;
    strong, b {
        font-weight: 600;
    }
}

.heading-font {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
}

.body-font {
    font-family: $font-family-base;
    font-weight: $font-weight-base;
    text-transform: none;
}

.color-body {
    color: var(--theme--color);
}

.accent-color {
    color: var(--theme--accent--color);
}

.text-muted {
    color: var(--theme--color--subtle) !important;
}

b, strong, .strong {
    font-weight: $font-weight-bold;
}

.prev-price {
    display: inline-block;
    position: relative;
    color: var(--theme--color--subtle);
    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $brand--red;
        transform: rotate(-8deg);
    }
}

// placeholder font-awesome size
i.fa-solid, i.fa-light, i.fa-regular, i.fa-sharp {
    display: inline-block;
    min-width: 1em;
    height: 1em;
}

// icon styling shims
.tripadvisor-icon, .tiktok-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
